import React, { Component,cloneElement } from 'react';
import PropTypes from 'prop-types'
import iScroll from './iScrollLite';
import cx from "classnames";
import './pullDownRefresh.css';

export default class PullToRefresh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false
    };
    this.xScrollRef = React.createRef();
    this.xScrollContentRef = React.createRef();

  }
  init() {
    if (!this.state.initialized) {
      let page = this.page;
      let enablePullDownRefresh = page.getPageConfig('enablePullDownRefresh');
      let onReachBottomDistance = Number.parseInt(page.getPageConfig('onReachBottomDistance') || 50);
      this.scroller = new iScroll(this.xScrollRef.current,this.page,enablePullDownRefresh,onReachBottomDistance);
      let backgroundTextStyle = page.getPageConfig('backgroundTextStyle');
      if(backgroundTextStyle === "light"){
        this.scroller.$pdiEle.css("filter","invert(100%)");
      }
      page.stopPullDownRefresh = () =>{
        if(this.scroller){
          return this.scroller.refresh();
        }else{
          return new Promise((resolve,reject)=>reject());
        }
      };
      page.startPullDownRefresh = () =>{
        if(this.scroller){
          return this.scroller.startPullDownRefresh();
        }else{
          return new Promise((resolve,reject)=>reject());
        }
      };

      this.setState({
        initialized: true
      });
    }
  }
  componentDidMount() {
    this.init();
    let disableScroll = this.page.getPageConfig("disableScroll");
    if(disableScroll){
      this.xScrollContentRef.current.style.overflow = "hidden";
    }
  }

  render() {
    let children = React.Children.map(this.props.children, (child) => {
      if (child === null) {
        return null;
      }

      return cloneElement(this.props.children, {
        ref: (wxReactPageElement) => {this.page = wxReactPageElement},

        pageId:this.props.pageId
      });
    });
    return (
      <div {...this.props} ref={this.xScrollRef} className = {cx('x-scroll',this.props.className)}
                           onTouchStart = {(event) => {if(this.scroller){this.scroller.handleStart(event)}}}
                           onTouchMove = {(event) => {if(this.scroller){this.scroller.handleMove(event)}}}
                           onTouchEnd = {(event) => {if(this.scroller){this.scroller.handleEnd(event)}}}
                           onTouchCancel = {(event) => {if(this.scroller){this.scroller.handleCancel(event)}}}
                           onScroll = {(event) => {if(this.scroller){this.scroller.handleScroll(event)}}}>
        <div className="x-content-center x-pull-down">
          <i className="x-pull-down-img x-icon-pull-down"/>
          <span className="x-pull-down-label"/>
        </div>
        <div className="x-scroll-content" ref={this.xScrollContentRef}>
          {children}
        </div>
      </div>
    );
  }
}
