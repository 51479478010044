import moment from "moment";
const types = ["year", "month", "day", "hour", "minute"];
const formatters = ["YYYY", "MM", "DD", "HH", "mm"];
export default class DateUtils {
    static getEnums() {
        let enumMap = new Map();
        for (let i = 31; i > 0; i--) {
            let binaryString = i.toString(2);
            if (binaryString.length < 2) {
                binaryString = "0000" + binaryString;
            } else if (binaryString.length < 3) {
                binaryString = "000" + binaryString;
            } else if (binaryString.length < 4) {
                binaryString = "00" + binaryString;
            } else if (binaryString.length < 5) {
                binaryString = "0" + binaryString;
            }
            let binaryStringArr = binaryString.split("");
            let typeStr = "", formatStr = "";
            let count = 0;
            for (let j = 0; j < binaryStringArr.length; j++) {
                if (!(binaryStringArr[j] + "" == "0")) {
                    count++;
                    typeStr += "," + types[j];
                    let sperator = "-";
                    if (j == 3) {
                        sperator = " "
                    } else if (j == 4) {
                        sperator = ":"
                    }
                    formatStr += sperator + formatters[j];
                } else {
                    typeStr += "";
                    formatStr += "";
                }
            }
            let key = typeStr.substring(1);
            let format = formatStr.substring(1);
            if (key.indexOf("hour") > -1 && key.indexOf("minute") <= -1) {
                // minute补0
                format += ":00";
            }
            if (key.indexOf("minute") > -1 && key.indexOf("hour") <= -1) {
                // hour补0
                format = format.replace(":mm", " 00:mm");

            } if (key.indexOf("month") > -1 && key.indexOf("year") <= -1 && key.indexOf("day") <= -1) {
                // day补0
                format = format.replace("MM", "MM-00");
            }
            if (key.indexOf("year") > -1 && key.indexOf("day") > -1 && key.indexOf("month") <= -1) {
                // month补0
                format = format.replace("YYYY", "YYYY-00");
            }
            let type = "";
            if (count == 5) {
                type = "datetime";
            } else if (i == 28) {
                type = "date";
            } else {
                type = "string";
            }
            enumMap.set(key, {
                dataType: type, format
            });
        }
        return enumMap;
    }

    static getAntdEnums() {
        let enumArr = [];
        enumArr.push({ key: { picker: "week" }, value: { dataType: "string", format: "YYYY-ww周" } });
        enumArr.push({ key: { picker: "quarter" }, value: { dataType: "string", format: "YYYY-Q季度" } });
        enumArr.push({ key: { picker: "date" }, value: { dataType: "date", format: "yyyy-MM-DD" } });
        enumArr.push({ key: { picker: "time" }, value: { dataType: "string", format: "HH:mm:ss" } });
        enumArr.push({ key: { picker: "month" }, value: { dataType: "string", format: "YYYY-MM" } });
        enumArr.push({ key: { picker: "year" }, value: { dataType: "string", format: "YYYY" } });
        enumArr.push({ key: { picker: "date", "showTime": true }, value: { dataType: "datetime", format: "YYYY-MM-DD HH:mm:ss" } });
        // enumArr.push({ key: { picker: "date", "showTime": true, format: "yyyy-mm-dd HH:mm" }, value: { dataType: "datetime", format: "yyyy-mm-dd HH:mm" } });
        // enumArr.push({ key: { picker: "date", "showTime": true, format: "yyyy-mm-dd HH" }, value: { dataType: "string", format: "yyyy-mm-dd HH" } });
        // enumArr.push({ key: { picker: "date", "showTime": true, format: "yyyy-mm-dd mm" }, value: { dataType: "string", format: "yyyy-mm-dd mm" } });
        // enumArr.push({ key: { picker: "time", format: "HH:mm" }, value: { dataType: "string", format: "HH:mm" } });
        // enumArr.push({ key: { picker: "time", format: "HH" }, value: { dataType: "string", format: "HH" } });
        // enumArr.push({ key: { picker: "time", format: "mm" }, value: { dataType: "string", format: "mm" } });
        return enumArr;
    }

    static getEnum(type, isPcx = false) {
        if (isPcx) {
            let enumArr = DateUtils.getAntdEnums();
            for (let i = 0; i < enumArr.length; i++) {
                if (DateUtils.isObjectValueEqual(type, enumArr[i].key)) {
                    return enumArr[i];
                }
            }
        } else {
            let enumMap = DateUtils.getEnums();
            return enumMap.get(type);
        }
    }

    static getDataType(type, isPcx = false) {
        let enumObj = DateUtils.getEnum(type, isPcx);
        if (isPcx) {
            return enumObj?.value?.dataType;
        }
        return enumObj.dataType;
    }

    static getFormat(type, isPcx = false) {
        let enumObj = DateUtils.getEnum(type, isPcx);
        if (isPcx) {
            return enumObj?.value?.format;
        }
        return enumObj.format;
    }

    static removeNullUndefined = (obj) =>
        Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});

    static isObjectValueEqual(a, b) {
        // 判断两个对象是否指向同一内存，指向同一内存返回 true
        if (a === b) return true // 获取两个对象键值数组
        a = DateUtils.removeNullUndefined(a);
        b = DateUtils.removeNullUndefined(b);
        let aProps = Object.getOwnPropertyNames(a);
        let bProps = Object.getOwnPropertyNames(b);
        // 判断两个对象键值数组长度是否一致，不一致返回 false
        if (aProps.length !== bProps.length) return false // 遍历对象的键值
        for (let prop in a) {
            // 判断 a 的键值，在 b 中是否存在，不存在，返回 false
            if (b.hasOwnProperty(prop)) {
                // 判断 a 的键值是否为对象，是则递归，不是对象直接判断键值是否相等，不相等返回 false
                if (typeof a[prop] === 'object') {
                    if (!DateUtils.isObjectValueEqual(a[prop], b[prop])) return false
                } else if (a[prop] !== b[prop]) {
                    return false
                }
            } else {
                return false
            }
        }
        return true
    }

    static isValid(value, format) { 
        return moment(value, format, true).isValid();
    }

    static parse(value, type, dataType, isPcx = false) {
        if (dataType == "string") {
            value = moment(value, DateUtils.getFormat(type, isPcx), true).valueOf();
        }
        return value;
    }

    static format(date, type, dataType, isPcx = false) {
        let value;
        if (dataType == "string") {
            value = moment(date).format(DateUtils.getFormat(type, isPcx));
        }
        return value;
    }
}