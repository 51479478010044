import React, {Component} from "react";
import PageContext from "core/framework/PageContext";
import OptionsUtils from "core/render/OptionsUtils";
import {get, omit,isObject} from 'lodash';

export default class BaseComponent extends Component {
  static contextType = PageContext;

  constructor(props, context) {
    super(props, context);
    this.__root__ = this.context;
  }

  getUseProps(componentName, key = "default", defaultValue) {
    let use = this.props.use;
    if (!this.props.use) {
      return defaultValue;
    }

    let result = get(use, "['" + componentName + "']." + key, defaultValue);
    if (isObject(result)) {
      result = omit(result, "id");
    }
    return result;
  }

  fireEvent(eventName, event, defaultAction, currentTarget = this) {
    /**
     *
     *   onClick(event)   => onMyClick(event)
     *
     *   onRowRender(text,record,index) = > onMyReowRender({text,record,index})
     *
     *
     */
    //如果参数只有一个 并且要透传 明确告诉我   event.disableSpread = true
    if (event && event instanceof Array && event.disableSpread !== true) {
      currentTarget.props[eventName] && currentTarget.props[eventName].bind(currentTarget)(...event);
    } else if (typeof event == "object") {
      //标准w3c 事件 或者平台封装可以cancel的事件 可以作为标准event传递 否则展开
      currentTarget.props[eventName] && currentTarget.props[eventName].bind(currentTarget)(event);
    } else {
      //event null || 透传数组
      currentTarget.props[eventName] && currentTarget.props[eventName].bind(currentTarget)(event);
    }

    if (!event instanceof Array && !event instanceof window.Event) {
      console.warn(eventName + "不符合标准事件规范，推荐采用w3c api  new CustomEvent('name',{cancelable:true})");
    }

    if (!(event && (event.cancel == true || event.defaultPrevented))) {
      if(event instanceof Array){
        defaultAction && defaultAction.apply(currentTarget,event);
      }else{
        defaultAction && defaultAction.apply(currentTarget,[event]);
      }

    }
  }

  hasLabelRef() {
    return !!this.props.labelRefColumnName;
  }

  getLabelRefColumnName() {
    return this.props.labelRefColumnName;
  }

  getOptionsData() {
    let {optionsRefDataId} = this.props;
    return OptionsUtils.getOptionsData.bind(this)(optionsRefDataId)
  }

  getOptions() {
    let {options, optionsDisabled, optionsFilter,} = this.props;
    return OptionsUtils.getOptions.bind(this)(options, optionsDisabled, optionsFilter)
  }


  getLabelRefValue(serialize = false, props = this.props) {
    let row = this.getLabelRefRow(props);
    if (row) {
      let result = row[this.getLabelRefColumnName()];
      if (serialize) {
        if (result && typeof result == "object") {
          result = result.toString();
        }
      }
      return result;
    } else {
      return;
    }
  }


  setLabelRefValue(value) {
    let row = this.getLabelRefRow();
    if (row) {
      return row[this.getLabelRefColumnName()] = value;
    } else {
      return;
    }
  }

  getLabelRefRow(props = this.props) {
    let row = props.labelRefRow;
    if (row) {
      let data = this.getLabelRefData();
      let idColumn = data.getIdColumn();
      let rowId = row[idColumn];
      return data.getRowByID(rowId);
    } else {
      return row;
    }

  }


  getLabelRefData() {
    let dataId = this.props.labelRefDataId;
    return this.getData(dataId);
  }

  hasRef() {
    return !!this.props.refColumnName;
  }

  getRefColumnName() {
    return this.props.refColumnName;
  }

  getRefColumnDef() {
    if (this.getRefData()) {

      return this.getRefData().getColumnDefs()[this.props.refColumnName];
    }
    return;
  }


  /**
   * 特别注意 如果是日期时间类型的字段 这里的值 返回为date对象 统一要求设置也为date对象
   * @returns {*}
   */
  getRefValue(serialize = false, props = this.props) {
    let row = this.getRefRow(props);
    if (row) {
      let result = row[this.getRefColumnName()];
      if (serialize) {
        if (result && typeof result == "object") {
          result = result.toString();
        }
      }
      return result;
    } else {
      return;
    }
  }

  setRefValue(value) {
    let row = this.getRefRow();
    if (row) {
      return row[this.getRefColumnName()] = value;
    } else {
      return;
    }
  }

  getRefRow(props = this.props) {
    let row = props.refRow;
    if (row) {
      let data = this.getRefData();
      let idColumn = data.getIdColumn();
      let rowId = row[idColumn];
      if (rowId) {
        return data.getRowByID(rowId);
      }
    }
  }

  //支持data的关联引用
  getLinkDataInfo() {

  }

  getRefData() {
    let dataId = this.props.refDataId;
    if (dataId) {
      return this.getData(dataId);
    }
    return;
  }

  getRefDataIdColumn() {
    return this.getRefData()?.getIdColumn() || 'key';
  }


  getData(dataId) {
    return this.callPageModelMethod(dataId);
  }

  getPage() {
    return this.callPageModelMethod();
  }


  callPageModelMethod(compid, method, ...params) {
    let page = this.__root__.wxPageDeclaration && this.__root__.wxPageDeclaration.$page;
    if (!compid) {
      return page;
    }
    if (compid && page) {
      let wxComp = this.__root__.wxPageDeclaration.$page.comp(compid);

      if (!method) {
        return wxComp;
      }
      return wxComp[method].call(wxComp, params, this);
    }
  }
}
