import DataStateLinker from './dataStateLinker';
import PropTypes from "prop-types";
import {_exRun} from "../utils";
import React from "react";
import wx from "../api/wxApi";
import WxPageComponent from "./WxPageComponent";
import PageContext from "./PageContext";


/**
 PageView.class(.w)  -> PageViewModel.class                     -> 用户代码(.js) ->      Page.js            ->              PageComponent  ->   WxPageComponent
                                                                                                                                              (pcx mx wx各有不同后缀 .mobile.js .pc.js .wx.js)
 render 			       ->   通用逻辑logic                           -> 业务逻辑       ->     提供了通用api                         react集成   ->       完成wx(多端支持)相关逻辑

                         1. model上派发生命周期事件的能力                                  1. 比如this.comp this.setData       1. 提供react相关api  		1. 部分方法是通过instance复制扩展的方法(满足hcr上层event this.disDispatchEvent的能力)
                         2. before after逻辑																				                                                              2. 比如taro集成逻辑在WxPageComponent.mobile.js 中实现。
                         3.在super.onLoad()之前初始化状态树                                                                                         3. 小程序的状态树定义和编译生成方法 在构造时候存贮
                         4。保证用户onload状态树初始化正常
                         5. super.onLoad 之后
 */


export default class PageComponent extends WxPageComponent{
  constructed() {
    super.constructed();
    this.reactRefs = {};
    this.__idRefMapping = {};
    //reactRefs 挂载到标准小程序定义json中的reactRefs域中
    this.wxPageDeclaration.reactRefs = this.reactRefs;
    this.wxPageDeclaration.__idRefMapping = this.__idRefMapping;
  }

  supportReactState(){
    //打通setState逻辑 支持用户js 定义state 并且 setState
    let $page = this.wxPageDeclaration.$page;
    if(this.wxPageDeclaration.$page.state){
      this.state = {
        ...this.state,
        ...this.wxPageDeclaration.$page.state
      };
    }
    Object.defineProperty($page, 'state', {
      get: ()=>{ return this.state }
    });
    $page.setState = this.setState.bind(this);
    $page.reactPageInstance = this;
  }

  mergeWxPageDeclarationFunction() {
    //需要合并 小程序定义页的函数 到 react的page对象上 hcr的事件查找逻辑需要
    let self = this;
    for (let name in this.wxPageDeclaration) {
      if (this.wxPageDeclaration.hasOwnProperty(name)) {
        if (typeof this.wxPageDeclaration[name] === "function") {
          if(this[name] && name != "getPage"){
            console.warn(`冲突的方法${name}`)
          }else{
            this[name] =  this.wxPageDeclaration[name].bind(this.wxPageDeclaration)
          }
        }
      }
    }
  }

  // 标准逻辑中需要这个api  但是createPageConfig中已经占用了这个名字 而且需要复制过来 所以这里写个空的api
  // 方便javascript编辑器代码提示和检查 运行时getPage被覆盖后重写
  getPage(){
  }

  getChildContext() {
    return {__root__: this};
  }

  onLoad(query) {


    this.mergeWxPageDeclarationFunction();
    super.onLoad(query);
    let userPage = this.wxPageDeclaration["$page"];
    console.log("页面onload之后初始化h5环境");
    if(wx.initenv){
      wx.initenv(userPage);
    }
    console.log(userPage);
    //reactRefs 挂载到标准小程序定义json中的reactRefs域中
    /*this.wxPageDeclaration.$page.reactRefs = this.reactRefs;
    this.wxPageDeclaration.$page.__idRefMapping = this.__idRefMapping;
    this.supportReactState();*/
  }

  componentWillUnmount() {
    if (this.onUnload && (typeof this.onUnload == "function")){
      this.onUnload();
    }
  }

  //历史问题保留 后续应该组件内统一调用fireEvent 这个方法可以去掉
  __$$callFn(name, args){
    if (!this[name] || (typeof this[name] !== "function")){
      throw new Error("页面必须定义'" + name + "'方法");
    }
    return this[name].apply(this, args);
  }

  //扩展上层使用react对接函数
  addReactRef(compid,name,id){
    if(compid){
      let refId = name ? (compid + "-" +  name) :compid;
      this.reactRefs[refId] = this.reactRefs[refId] || React.createRef();
      if(id){
        this.__idRefMapping[id] = this.reactRefs[refId];
      }
      return this.reactRefs[refId];
    }
  }

  getModuledCss(classNames){
    if(!classNames){
      return "";
    }
    let s = this.wxPageStyle || {};

    //cssModule
    let cssModuleClassName = classNames.split(' ').map(function (item) {
      return s[item] || "";
    });
    //className
    classNames = classNames + " " + cssModuleClassName.join(' ');
    //console.log("模块化css:" + classNames);
    return classNames;
  }


  getData(dataId){
    return this.callPageModelMethod(dataId);
  }
  /*
  会合并所以这里不定义getPage

  getPage(){
    return this.callPageModelMethod();
  }
  */



  callPageModelMethod(compid,method,...params){
    let page = this.wxPageDeclaration && this.wxPageDeclaration.$page;
    if(!compid){
      return page;
    }
    if(compid && page) {
      let wxComp = this.wxPageDeclaration.$page.comp(compid);

      if(!method){
        return wxComp;
      }
      return wxComp[method].call(wxComp, params, this);
    }
  }
}

PageComponent.childContextTypes = {
  __root__: PropTypes.object
};

