import React, {Fragment} from "react";
import {getRefRow,getRefData, getRefUserData} from 'core/utils';

function isEmpty(value) {
  return value===undefined || value===null || value==="";
}


export function processBindProps(props, page) {
  let userData = getRefUserData(props, page);
  props = processUse(props);
  if(page.state.loading){
    return props;
  }
  return processCommonProps(props, userData,page);
}

function processCommonProps(props, userData,page) {
  if (!userData && props['refColumnName']) {
    //有绑定列 但是无记录
    props = {...props};
    props.disabled = true;
    props.className = props.className + " x-disabled";
  }
  return processHidden(props, userData,page);
}

function processHidden(props, userData = {},page) {
  let {hidden} = props;
  if (props['refColumnName']) {
    let columnUserData = userData[props['refColumnName']];
    if (columnUserData && columnUserData.hidden) {
      return <Fragment></Fragment>;
    }
  }


  if (hidden || userData.hidden) {
    return <Fragment></Fragment>;
  }
  props = processDisabled(props, userData);
  props = processReadOnly(props, userData);
  props = processRequired(props, userData,page);
  props = processConstraint(props, userData,page);


  return props;
}

function processConstraint(props, userData = {},page) {
  let columnUserDataConstraint = false;
  if (props['refColumnName']) {
    let columnUserData = userData[props['refColumnName']];
    if (columnUserData) {
      columnUserDataConstraint = columnUserData.constraint;
    }
  }

  if (columnUserDataConstraint && columnUserDataConstraint.val == false) {
    props = {...props};
    props.constraint = true;
    let errorMessage = ((getRefData(props,page)?.schema.schema.items.props[props['refColumnName']].label) || props['refColumnName']) + `约束检查失败`;
    props.errorMessage = columnUserDataConstraint.msg || errorMessage;
    props.className = props.className + " x-constraint";
  }
  return props;
}

function processDisabled(props, userData = {}) {
  let {disabled} = props;

  let columnUserDataDisabled = false;
  if (props['refColumnName']) {
    let columnUserData = userData[props['refColumnName']];
    if (columnUserData) {
      columnUserDataDisabled = columnUserData.disabled;
    }
  }

  if (disabled  || (columnUserDataDisabled && columnUserDataDisabled.val == true)) {
    props = {...props};
    props.disabled = true;
    props.className = props.className + " x-disabled";
  }
  return props;
}


function processReadOnly(props, userData = {}) {

  //只有readonly格式是个数的  {readonly:true} 没有val 因为没有msg


  let {readonly} = props;

  let columnUserDataReadOnly = false;
  if (props['refColumnName']) {
    let columnUserData = userData[props['refColumnName']];
    if (columnUserData) {
      columnUserDataReadOnly = columnUserData.readonly;
    }
  }


  if (readonly || columnUserDataReadOnly) {
    props = {...props};
    props.readonly = true;
    //data只有只读 没有禁用 所以 对接 组件添加了disabled属性
    props.disabled = true;
    props.className = props.className + " x-readonly";
  }
  return props;
}

function processRequired(props, userData = {},page) {
  let {required} = props;

  let columnUserDataRequired = false;
  if (props['refColumnName']) {
    let columnUserData = userData[props['refColumnName']];
    if (columnUserData) {
      columnUserDataRequired = columnUserData.required;
    }
  }
  if (required || (columnUserDataRequired && columnUserDataRequired.val == true)) {
    let refRow = getRefRow(props, page);
    let refValue = refRow?.[props['refColumnName']];
    props = {...props};
    props.required = true;
    if(isEmpty(refValue)){

      let requiredMessage = ((getRefData(props,page)?.schema.schema.items.props[props['refColumnName']].label) || props['refColumnName']) + "不允许为空";

      props.requiredMessage = columnUserDataRequired.msg || requiredMessage;
      props.className = props.className + " x-required";
    }
  }
  return props;
}

/**
 *

 ext:use 处理逻辑

 use:{
    Button:{
      "default":{
          name:"aaa",
          text:"bbb"
      },
      "key1":{

      }
    }
 }
 */

function processUse(props, useInfo = {}) {
  let {use} = props;
  if (use && React.isValidElement(use)) {
    React.Children.forEach(use.props.children, (child) => {
      let childProps = child.props;
      childProps = processUse(childProps, useInfo);

      let {use_name, id,use_key = "default", ...other} = childProps;
      let childInfo = useInfo[use_name];
      useInfo[use_name] = useInfo[use_name] || {};
      useInfo[use_name][use_key] = other;
    });
    let {...result} = props;
    result.use = useInfo;
    return result;
  }
  return props;
}

/*
单前process和其他process处理逻辑 不同 其他process在render中处理
当前为  挂到data上通过监听data上的loading状态 来处理自身的setState
主要应用于
  bind:ref   -> input类的
  bind:items -> 循环对象
  bind:options -> 备选对象 无循环概念
  bind:data   -> search 之类组件

  同时考虑到 button之类的逻辑  操作编译时候 也会生成props  refDataId
  page上提供2个属性
  1. 禁用 页面加载动画  自定义走common.js
  2. 禁用 局部加载动画  自动走标准 组件上的 loading属性 如果可以自定义的话

  mobx 是wxsys包的东西
  core是 common包的东西 理论上 common不应该依赖wxsys内的东西
 */
export function processLoadingProps(props,page,comp){
  if(page.props.disableCompLoading){
    return;
  }
  let data = getRefData(props, page);
  if(data){
    return data.reaction(
      () => data.loading.get(),
      loading => {
        if(page.state.loading == true){
          //页面加载中  组件不感知loading效果
        }else {
          if (loading) {
            comp.setState({
              loading:true
            })
          } else {
            comp.setState({
              loading:props.loading
            })
          }
        }
      }
    );
  }

}



