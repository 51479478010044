import history from '../../appHistory.js';
import {doThen, doCatch, isX5App, resolveLocalFile, normalizeUrl} from '../../utils';
import {last} from "lodash";
import ReactDOM from 'react-dom';
import URI from "urijs";
import tabbar from '../../tabbar';
import CryptoRequest from "../network/crypto-request";

function _formatUrl(params) {
  let _url = normalizeUrl(params.url);
  if(!(params.prependServicePath == false)){
    _url = addMicroServicePath(_url);
  }
  /**
   *   微服务环境下处理pc类url推到mobile的url的情况
   *
   *   根据serviceMetaInfo推之后 如果没有计算出来 loader中再loadPageModel的时候
   *   会进行兜底处理
   *   支持没有写serviceMetaInfo还需要推出pcx url的场景 例如 流程的dialog
   */

  _url = getMobileUrl(_url);

  return _url;
}

function getMobileUrl(url){
  let uri = URI.parse(url);
  let mobileUrl = _getMobileUrl(uri.path);
  if(mobileUrl){
	//参数以外面传的为准，菜单配置的参数认为是功能树上使用的参数
    uri.path = URI.parse(mobileUrl).path;
  }

  return URI.build(uri).toString();
}

function isInServiceMetaInfo(url){
  let uri = URI.parse(url);
  return _isInServiceMetaInfo(uri.path);
}

function  _isInServiceMetaInfo(path,menus){
  menus = menus || window.microService.menus;
  for(let index=0;index < menus.length;index ++ ){
    let item = menus[index];
    if(item.children && item.children.length > 0){
      let result = _isInServiceMetaInfo(path,item.children);
      if(result){
        return result;
      }
    }else{
      if(item.originUrl.replace(".w","") == path){
        //如果是制作模式生成的pcx页面 需要用移动制作的url
        return true;
      }
    }
  }
}


function  _getMobileUrl(url,menus){
  if(window.microService.isMicroService){
    if(!menus){
      menus = window.microService.menus;
    }
    for(let index=0;index < menus.length;index ++ ){
      let item = menus[index];
      if(item.children && item.children.length > 0){
        let result = _getMobileUrl(url,item.children);
        if(result){
          return result;
        }
      }else{
        if(item.originUrl.replace(".w","") == url){
          //如果是制作模式生成的pcx页面 需要用移动制作的url
          if(item.types?.indexOf("make-mobile-pcx")!=-1){
            return url;
          }else{
            url = item.url;
          }
          return url;
        }
      }
    }
  }
}

function addMicroServicePath(url) {
  if (window.microService.isMicroService) {
    //TODO: 应用内页面跳转 自动拼接 basePath和contextPath
    let props = last(getCurrentReactPages()).props;
    //本地加载的页面不自动拼接微服务路径
    if(props.loadMode == "remote"){
      if (props && props.serviceName) {
        let contextName = props.contextName;
        if(contextName.indexOf("pcx")!=-1){
          contextName = contextName.replace("pcx",'mobile');
        }
        url = "/" + props.serviceName + "/" + contextName + url;
      }
    }
  }
  return url;
}

function checkUrl(url) {
  return true;
  let result = false;
  if (window.microService.isMicroService) {
    return true;
  } else {
    if (url && url.indexOf("?") != -1) {
      url = url.split("?")[0];
    }
    let routes = window.routes;
    routes.forEach((route => {
      if (route.pattern.exec(url)) {
        result = true;
      }
    }));
  }
  return result;
}

function navigateTo(params) {
  let _url = _formatUrl(params);
  if (checkUrl(_url)) {
    let currentUrl = history.location.pathname + history.location.search;
    if(currentUrl != _url){
      _url = CryptoRequest.parse(_url).toString();
      history.push(_url);
      doThen(params);
    }
  } else {
    doCatch(params, {
      errMsg: "navigateTo:fail page \"" + params.url + "\" is not found"
    });
  }
}

function navigateToMiniProgram(params) {
  if (!params || !params.appId) {
    doCatch(params, {
      errMsg: "navigateToMiniProgram:fail parameter error: parameter.appId should be String instead of Undefined;"
    });
    return;
  }
  wx.showModal({
    title: "提示",
    content: "已成功跳转到小程序[" + params.appId + "]运行,点击返回,重新进入小程序!",
    showCancel: false,
    confirmText: "返回",
    success: function () {
      if (global._app.onShow) {
        global._app.onShow();
      }
    }
  });
  doThen(params);
}


function navigateBackMiniProgram(params) {
  doThen(params, {
    errMsg: "navigateBackMiniProgram:ok"
  });
}


function redirectTo(params) {
  let _url = _formatUrl(params);
  if (checkUrl(_url)) {
    _url = CryptoRequest.parse(_url).toString();
    history.replace(_url);
    doThen(params);
  } else {
    doCatch(params, {
      errMsg: "redirectTo:fail page \"" + params.url + "\" is not found"
    });
  }
}

//tabbar组件回调使用 要求外面已经处理好微服务的url
function switchTo(url) {
  let params = {
    url:url,
    prependServicePath:false
  };
  let _url = _formatUrl(params);
  if (checkUrl(_url)) {
    //用push 会造成浏览器history 多返回  用replace会造成移动门户 数据加载有问题 因为功能树是用data实现的 多次加载防止有性能问题
    history.push(_url);
    doThen(params);
  } else {
    doCatch(params, {
      errMsg: "switchTo:fail page \"" + params.url + "\" is not found"
    });
  }
}


function navigateBack(params = {delta: 1}) {
  let delta = params.delta || 1;
  if (getCurrentReactPages().length >= delta) {
    if (delta > 1) {
      let unmountDelta = delta;
      Array.prototype.map.call(document.querySelectorAll('#container>.page_container'), (e) => e).reverse().forEach((element, index) => {
        if (unmountDelta > 0) {
          unmountDelta--;
          if (!element.classList.contains('main_page')
            && !element.classList.contains('current_page')
            && !element.classList.contains('tab_page')) {
            ReactDOM.unmountComponentAtNode(element);
          }
        }
      });
    }
    history.go(-1 * delta);
  } else {
    document.querySelectorAll('#container>.page_container').forEach((element, index) => {
      if (!element.classList.contains('main_page') && !element.classList.contains('tab_page')) {
        ReactDOM.unmountComponentAtNode(element);
      }
    });
    if (document.querySelectorAll('#container>.current_page').length === 0) {
      document.querySelector('#container>.main_page').classList.add('current_page');
    }
  }
  setTimeout(function(){
    doThen(params);
  },500);
}

/*url	String	是	需要跳转的 tabBar 页面的路径（需在 app.json 的 tabBar 字段定义的页面），路径后不能带参数
success	Function	否	接口调用成功的回调函数
fail	Function	否	接口调用失败的回调函数
complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）*/
function switchTab(params) {
  let switchResult = tabbar.switchTab(params.url);
  if (switchResult === false) {
    doCatch(params, {
      errMsg: "switchTab:fail can not switch to no-tabBar page"
    });
  } else {
    doThen(params);
  }
}

function reLaunch(params) {
  window.location.reload();
}

export {
  navigateTo,
  redirectTo,
  navigateBack,
  switchTo,
  switchTab,
  reLaunch,
  navigateToMiniProgram,
  navigateBackMiniProgram,
  _formatUrl,
  checkUrl,
  isInServiceMetaInfo
};
