export default class ErrorBoll {
  static enable = false;
  static errorCount = 0;
  constructor() {
    this.badgeStyle = document.querySelector("#errorBollStyle");
    if(!this.badgeStyle){
      this.badgeStyle = document.createElement("style");
      this.badgeStyle.setAttribute("id",'errorBollStyle');
      this.badgeStyle.setAttribute("type", "text/css");
      document.getElementsByTagName("head")[0].appendChild(this.badgeStyle);
    }
    //启用错误提示球
    if(ErrorBoll.enable){
      return;
    }
    ErrorBoll.enable = true;
    if (!(window.localStorage && localStorage.getItem("uixDevTools") == "false")) {
      import('./devTools/uixDevTools');
    }
    if (typeof window.onerror === "function") {
      window.oldonerror = window.onerror;
    }
    window.onerror =  (msg, url, line, col, error) =>{
      setTimeout( ()=> {
        ErrorBoll.errorCount += 1;
        window.uixDevTools.showMessage("检测到资源发生错误，请检查,共  " + ErrorBoll.errorCount + " 处错误");
      }, 200)
      if (window.oldonerror) {
        window.oldonerror.apply(window, arguments);
      }
    };
  }

  static resetErrorCount(){
    ErrorBoll.errorCount = 0;
    let badgeStyle = document.querySelector("#errorBollStyle");
    badgeStyle && (badgeStyle.innerText = "");
  }
}
