import {Component} from "react";
import DataStateLinker from "./dataStateLinker";
import mitt from 'mitt';
import {findPageByRoute} from 'core/router';




export default class WxPageComponent extends Component{
  constructor(props,context) {
    super(props, context);
    /**
     *  route是页面间消息通信的基础  基于router的消息通信 类似 pc的shell总线
     *  这里注意路径不带.w
     *  同时url中也不带.w
     *  小程序中页面列表也不带。w
     *  react中页面也不是。w结尾
     *  .w的处理全部在入口api上处理干净
     *
     *  原本.w的处理在 wx.navigate 上层高阶里面去掉的
     *  现在在 page的 navigate 和compose中提前去掉了 否则 messagechannel判断有问题
     *
     */

    this.route = this.props.location.pathname  +   (this.props.location.search || "");
    this.searchs = this.props.searchs;
    this.emitter = mitt();
    this.emitter.on('message', this.onMessage.bind(this));
  }

  getOpener() {
    /**
     * 兼容场景
     * 0. pageFrame windowDialog this.navigateTo 页面打开其他页面
     * 1. 小程序web-view场景 wx.miniProgram
     * 2. 浏览器中移动门户内 通过openerRoute 查找
     * 3. 浏览器中 pc门户内 通过openerRoute 查找
     * 4. 直接打开页面 或者iframe被别人嵌入

     实现逻辑

     * 存在openerRoute
     * 1. 说明是pageFrame场景
     * 2. 页面内通过this.navigateTo打开的场景
     *
     * 没有openerRoute(默认)  默认opener应该是容器  容器有三种 (优选查找排序)
     *   1. 小程序中 web-view  wx.miniProgram
     *   2. 门户 pc或者mobile
     *   3. iframe
     *   4. window.open
     *
     *
     *
     * 调整方案为 门户打开的页面 openerRoute = $shell ui2 场景  $router uix场景  后续兼容 antd pro的门户
     *  实现机制:
     *     1. this.navigateTo 会自动添加 openerRoute= this.route  移动门户主动传递openerRoute=$router
     *     2. wx.uixPageRender (pageFrame场景 pc门户场景)  外部主动指定
     *           pageFrame   openerRoute:this.route
     *           pc的widget      openerRoute:$shell
     *           pc门户打开  openerRoute:$shell
     *           pc中页面通过windowContainer compose pcx的页面 $render 类似于标准的ReactDom。render
     *
     *
     *
     *
     */


    if (this.props.params["openerRoute"]) {
      let openerRoute = this.props.params["openerRoute"];
      if(openerRoute == "$shell"){
        return {
          postMessage:(message)=>{
            message.source = this.route;
            justep.Shell.fireEvent("message",message);
          }
        }
      }else if(openerRoute == "$router"){
        return {
          postMessage:(message)=>{
            message.source = this.route;
            getApp().onMessage && getApp().onMessage(message);
          }
        }
      }else if(openerRoute == "$render"){
        return this.opener;
      }else {
        let page = findPageByRoute(openerRoute);
        if (page){

          return {
            postMessage:(message)=>{
              message.source = this.route;
              page.postMessage(message);
            }
          };
        }else {
          //opener页面已经关闭场景
          return {
            postMessage:(messaege)=>{
              console.warn(`${openerRoute} 页面已经不存在 消息发送失败`);
            }
          }
        }
      }
    }


    if (window.navigator.userAgent.indexOf('miniprogramhtmlwebview')!=-1) {
      /**
       *  小程序web-view场景  不是pageFrame 一定是小程序的父页面  走标准逻辑
       *  wx.miniProgram.postMessage  向小程序发送消息，会在特定时机（小程序后退、组件销毁、分享）触发组件的 message 事件
       *  不需要根据openerRoute 查找 因为小程序底层已经查找好了 出发到 web-view组件的message事件上
       */
      return {
        postMessage:(message)=>{
          message.source = this.route;
          wx.miniProgram.postMessage(message);
        }
      };
    }else{
      //iframe 场景或者 window.open场景打开 或者直接打开场景
      //首先找iframe场景
      if(window.parent && window.parent != window){
        return {
          postMessage:(message)=>{
            message.source = this.route;
            window.parent.postMessage(JSON.stringify(message));
          }
        };
      }else if(window.opener){
        return {
          postMessage:(message)=>{
            message.source = this.route;
            window.opener.postMessage(JSON.stringify(message));
          }
        };
      }else {
        //直接浏览器开一个普通页面的场景 仍然走 app的onMessage api方便用户接管后处理
        return {
          postMessage:(message)=>{
            message.source = this.route;
            getApp().onMessage && getApp().onMessage(message);
          }
        };
      }
    }
  }
  postMessage(message,target = this){
    target.emitter.emit('message',message);
  }

  onMessage(message){

  }

  constructed(){
    //兼容性保留
    this.wxPageDeclaration.basePath = this.props.serviceName;
    this.wxPageDeclaration.contextPath = this.props.contextName;
    this.wxPageDeclaration.parentPath = this.props.parentPath;

    this.basePath = this.props.serviceName;
    this.contextPath = this.props.contextName;

    //标准属性
    this.parentPath = this.props.parentPath;
    this.serviceName = this.props.serviceName;
    this.contextName = this.props.contextName;

    this.wxPageDeclaration.location = this.props.location;
    this.pagePath = this.props.location.pathname;
    let path =  this.pagePath.replace(/\/[0-9]+$/, '');
    this.wxPageDeclaration.route = path.replace(/^\//, "");
    /**
     *   data state linker -
     *   1. link wxPage data with react page state
     *   2. inject setData method to wxPage
     *   3. inited datalinker after init method wrapper
     *        because of init datastatelinker will trigger reactPage setState then will find and call wrppered Method
     *   data flow
     *   wxPage@data -> reactPage@state -> event -> wxPageMethod -> wxPage.setDate - > reactPage@state
     *
     */
    this.dataStateLinker = new DataStateLinker(this.wxPageDeclaration,this);
    this.config = this.getPageConfig();
    this.config.path = this.pageId;

    this.setCurrent();
  }

  getPageConfig(key){
    let config = Object.assign({},getApp().getAppConfig()['window'],this.wxPageConfig);
    if(key){
      return config[key];
    }else{
      return config;
    }
  }


  setCurrent(){
  }

  // onLoad
  onLoad () {
    this.wxPageDeclaration.owner = this;
    this.wxPageDeclaration.onLoad && this.wxPageDeclaration.onLoad(this.props.params);
    this.emitter.emit('onLoad',this.props.params );
  }

  // onReady
  onReady () {
    this.wxPageDeclaration.onReady && this.wxPageDeclaration.onReady();
    this.emitter.emit('onReady');
  }

  // 对应 onShow
  onShow () {

    this.setCurrent();
    this.wxPageDeclaration.onShow && this.wxPageDeclaration.onShow();
    this.emitter.emit('onShow');
  }

  // 对应 onHide
  onHide () {
    this.wxPageDeclaration.onHide && this.wxPageDeclaration.onHide();
    this.emitter.emit('onHide');
  }

  // 对应 onPullDownRefresh
  onPullDownRefresh () {
    this.wxPageDeclaration.onPullDownRefresh && this.wxPageDeclaration.onPullDownRefresh();
    this.emitter.emit('onPullDownRefresh');
  }

  onReachBottom () {
    this.wxPageDeclaration.onReachBottom && this.wxPageDeclaration.onReachBottom();
    this.emitter.emit('onReachBottom');
  }

  onUnload(){
    this.wxPageDeclaration.onUnload && this.wxPageDeclaration.onUnload();
    this.emitter.emit('onUnload');
  }

}
