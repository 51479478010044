
export default class DataStateLinker{

  /**
   *

=======理论==========


   上层编译会形成组件树

   _compTree:{
        page1:{
            depends:["page1.compid","restdata1.current","list1.listItem[].name"]
        }

   }


   react 第一次渲染会形成 渲染的实例表
   wrapperInstanceMap
   {
        "${compid}":"wrapperedRenderInstance"
   }

   其中compid的值 就是 小程序规范中的 data 即 状态树的 path

   那么：

   每次小程序setData({path:value})
      1. （直接依赖）  根据path从 渲染实例表 中可以确定  应该渲染的 react实例

      2. （外部依赖） 根据path从 组件树 中可以找到哪些组件依赖了这个数据，
          那么根据 获取到了 组件的原始id之后 可以推出(理论的)compid

          有了compid 即可从 渲染实例表中找到需要 渲染的react实例

=======优化==========

   获取到多个需要重绘的实例后，  根据组件树 去掉可能多次重绘的子节点

   最后 执行重绘


   */

  constructor(wxPageDeclaration,reactPageInstance){
      this.setStateQueue = [];
      this.wxPageDeclaration = wxPageDeclaration;
      this.reactPageInstance = reactPageInstance;

      //兼容wxPage中没有定义data的情况
      this.wxPageDeclaration.data = this.wxPageDeclaration.data || {};

      //this.wxPageDeclaration._dataDependencies = this.wxPageDeclaration._dataDependencies || {};
      this.transformDataDependencies();
      this.wxPageDeclaration.setData = (options) =>{
        this.setData(options);
      };

      this.reactPageInstance.wrapperInstanceMap = {
        compId:{},
        id:{}
      };
      this.reactPageInstance.state = {data:this.wxPageDeclaration.data};

  }

  transformDataDependencies(){

    let dependMap = this.dependMap = {};
    let compTree = this.wxPageDeclaration.__compTree;
    function _transformDataDependencies(_compTree){
      for(let key in _compTree){
        if(key != "depends"){
          let value = _compTree[key];
          let depends = value['depends'];
          for(let depend of depends){
            //路径拆分  依赖叶子节点 必然依赖父节点
            while(depend){
              dependMap[depend] = dependMap[depend] || [];
              if(dependMap[depend].indexOf(key) == -1){
                dependMap[depend].push(key);
              }
              depend = depend.substring(0,depend.lastIndexOf("."));
            }
          }


          _transformDataDependencies(_compTree[key]);
        }
      }
    }
    _transformDataDependencies(compTree);
  }

  setData(options) {
    let type = DataStateLinker.getDataType(options);
    if (type != "Object") {
      throw new Error("类型错误\n , setData accepts an Object rather than some " + type);
    }

    for (let path in options) {
      //小程序setData api需要保证小程序的data是对的 并且对象引用不变
      let dataInfo = DataStateLinker.getObjectByPath(this.wxPageDeclaration.data, path);
      let obj = dataInfo.obj;
      let key = dataInfo.key;
      if (obj) {
        obj[key] = options[path];
      }
    }

    if (this.reactPageInstance.directRender && window.disableDirectRender != true) {
      let changedLength = Object.keys(options).length;
      if(changedLength < 300){
        this.directRender(options);
      }else{
        console.log("数据变化超过300:"+ changedLength + ",调整为全量渲染");
        this.setState(this.wxPageDeclaration.data);
      }

    } else {
      this.setState(this.wxPageDeclaration.data);
    }
  }

  directRender(options){
    let willRenderInstances = [];
    // 1. 直接依赖
    for (let path in options) {
      let instance = this.reactPageInstance.wrapperInstanceMap.compId[path];
      instance && willRenderInstances.push(instance);
      //2. 外部依赖
      let dependPath = path.replace(/\[[0-9]*\]/g,"[]");
      let dependIds = this.dependMap[dependPath];
      if(dependIds){
        for(let dependId of dependIds){
          let dependComps =  this.reactPageInstance.wrapperInstanceMap.id[dependId];
          if(dependComps){
            willRenderInstances = willRenderInstances.concat(dependComps);
          }
        }
      }
    }
    //去重

    willRenderInstances = [...new Set(willRenderInstances)];
    for(let willRenderInstance of  willRenderInstances){
      willRenderInstance.forceUpdate();
    }
  }

  setState(data){
    this.setStateQueue.push(data);
    this._setState();
  }

  _setState(){
    if(this.isSettingState){
      return;
    }
    let newState = this.setStateQueue.pop();
    this.setStateQueue = [];
    if(newState){
      this.isSettingState = true;

      this.reactPageInstance.setState(({data}) =>{

        return  {data:this.wxPageDeclaration.data};
      },()=>{
        this.isSettingState = false;
        this._setState();
      });
    }
  }



  static getDataType(obj){
    return Object.prototype.toString.call(obj).split(" ")[1].split("]")[0]
  }
  static getObjectByPath(obj, path) {
    let item = undefined, key = undefined, unit = obj;
    for (let items = DataStateLinker.parsePath(path), c = 0; c < items.length; c++){
      if (Number(items[c]) === items[c] && items[c] % 1 === 0){
        if (!Array.isArray(unit)){
          item[key] = [];
          unit = item[key];
        }
      }else{
        if (!DataStateLinker.isPlainObject(unit)){
          item[key] = {};
          unit = item[key];
        }
      }

      key = items[c];
      item = unit;
      unit = unit[items[c]];
    }
    return {
      key: key,
      obj: item,
    }
  }
  static isPlainObject(obj){
    return obj && (Object.prototype.toString.call(obj)==="[object Object]");
  }
  static parsePath(path) {
    let result = [];
    let item = "";
    let isArray = false;
    let isNumber = false;
    for (let length = path.length, i = 0, c = 0; c < length; c++) {
      let s = path[c];
      if ("\\" === s){
        if (c + 1 < length && ("." === path[c + 1] || "[" === path[c + 1] || "]" === path[c + 1])){
          item += path[c + 1];
          c++;
        }else{
          item += "\\";
        }
      }else if ("." === s){
        if (item){
          result.push(item);
          item = "";
        }
      }else if ("[" === s) {
        if (item){
          result.push(item);
          item = "";
        }
        if (0 === result.length){
          throw new Error("path can not start with []: " + path);
        }
        isNumber= true;
        isArray = false;
      } else if ("]" === s) {
        if (!isArray){
          throw new Error("must have number in []: " + path);
        }
        isNumber= false;
        result.push(i);
        i = 0;
      } else if (isNumber) {
        if (s < "0" || s > "9"){
          throw new Error("only number 0-9 could inside []: " + path);
        }
        isArray = true;
        i = 10 * i + s.charCodeAt(0) - 48;
      } else
        item += s;
    }

    if (item){
      result.push(item)
    }

    if (0 === result.length){
      throw new Error("path can not be empty");
    }
    return result
  }
}
