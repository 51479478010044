import URI from 'urijs';

window.justep = window.justep || {Shell:{
    showPage:function(url){
      let searchInfo = new URI(url).query(true);
      let title = searchInfo.title;

      wx.navigateTo({
        url: url,
        modal: {
          width: "100%",
          style: {top: 0,
                  maxWidth: "100%",
                  height: "100%",
                  margin:0
          },
          title:title,
          bodyStyle: {minHeight: "calc(100vh - 56px)"}
        }
      })
    },
    closePage:function(){
      wx.navigateBack();
    }
}};
