import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import history from './appHistory'
import {getAppConfig, isX5App, isWeixinBrowser, isStandalone,isIphonex} from './utils';
import {switchTo} from './api/ui/navigate';
import _ from 'lodash';
import detector from 'detector';

class Tabbar extends Component {
    constructor(...args) {
        super(...args);
        this.state = {
            activeIdx: 0
        };
        this.header = document.querySelector('#header');
        this.container = document.querySelector('#container');
        this.tabbar = document.querySelector('#tabbar');
    }

    isTabPage(path) {
        let list = this.state.list;
        let p = path.replace(/\?(.*)$/, '').replace(/\.wxml$/, '');
        //let index = list.findIndex(item => item.pagePath === p);
        let index = this.findIndex(list, p);
        return index != -1;
    }
    getTabPagePath(path) {
      let list = this.state.list;
      let p = path.replace(/\?(.*)$/, '').replace(/\.wxml$/, '');
      //let index = list.findIndex(item => item.pagePath === p);
      let index = this.findIndex(list, p);
      if(index != -1){
        return list[index].pagePath
      }
    }

    reset() {
        let tabBar = getAppConfig('tabBar');
        let list = (tabBar && tabBar.list) || [];
        let p = history.location.pathname;
        let pathInfos = location.pathname.split("/");
        if(pathInfos.length >= 4 && pathInfos[2].endsWith("app")){
          let serviceName = pathInfos[1];
          let contextName = pathInfos[2];
          for (let item of list){
            item.serviceName = serviceName;
            item.contextName = contextName;
          }
        }
        this.setState({
            ...tabBar,
            activeIdx: this.findIndex(list, p)
        })
    }

    findIndex(list,p){
      return _.findIndex(list, item => {
        //短路径匹配
        if(item.pagePath.indexOf(p) == 0){
          return true;
        }
        if(item.serviceName && item.contextName){
          let fullPagePath = "/"+ item.serviceName + "/"+ item.contextName + item.pagePath;
          if(fullPagePath.indexOf(p) == 0){
            return true;
          }
        }
        return false;
      })
    }

    show(path, config) {
        let list = this.state.list;
        let p = path.replace(/\?(.*)$/, '').replace(/\.wxml$/, '');
        let navigationStyle = getAppConfig('navigationStyle');
        navigationStyle = config.navigationStyle || navigationStyle || 'default';
        this.setState({
            activeIdx: this.findIndex(list, p),
            navigationStyle: navigationStyle
        });
    }

    switchTab(path) {
        let list = this.state.list;
        if (this.state.activeIdx != -1 && list[this.state.activeIdx]) {
            let activePath = list[this.state.activeIdx].pagePath;
            if (activePath === path) return;
        }
        let item = this.state.list.find((item, index) => {
            return path == item.pagePath;
        });
        if (item) {
            switchTo(path);
        } else {
            return false;
        }
    }

    onItemTap(idx, path) {
        if (idx === this.state.activeIdx) return;
        let item = this.state.list.find((item, index) => {
            return idx == index
        });
        switchTo(item.pagePath);
    }

    setTabBarBadge(index, text) {
        let item = this.state.list.find((item, _index) => {
            return _index == index;
        });
        item.badge = text;
        this.setState({
            list: this.state.list
        });
    }

    componentDidUpdate() {
        const {position, activeIdx} = this.state;
        if (position == "top") {
            if (this.state.navigationStyle == "custom") {
                this.header.style.height = "0";
                this.tabbar.style.top = "0";
                this.tabbar.style.bottom = "auto";
                this.container.style.top = "48px";
                this.container.style.bottom = "0";
            } else if (isStandalone) {
                this.tabbar.style.top = "42px";
                this.tabbar.style.bottom = "auto";
                this.container.style.top = "90px";
                this.container.style.bottom = "0";
            } else {
                this.header.style.height = "0";
                this.tabbar.style.top = "0";
                this.tabbar.style.bottom = "auto";
                this.container.style.top = "48px";
                this.container.style.bottom = "0";
            }
        } else {
            if (this.state.navigationStyle == "custom") {
                this.header.style.height = "0";
                this.container.style.top = "0";
                this.container.style.bottom = "48px";
            } else if (isStandalone) {
                if (detector.os.name == "ios" && detector.os.version >= 8) {
                    if (isIphonex) {
                        this.container.style.top = "84px";
                    } else {
                        this.container.style.top = "62px";
                    }
                } else {
                    this.container.style.top = "42px";
                }
                if (isIphonex) {
                    this.container.style.bottom = "82px";
                } else {
                    this.container.style.bottom = "48px";
                }
            } else {
                this.header.style.height = "0";
                this.container.style.top = "0";
                this.container.style.bottom = "48px";
            }
        }
        if (activeIdx == -1 || activeIdx == null || position == "top") {
            this.container.style.bottom = "0px";
        }
    }

    render() {
        let {state} = this;
        let list = state.list;
        let active = state.activeIdx;
        let shown = list && list.length > 0;
        if (!shown) return null;
        let hidden = active == -1 || active == null;
        let top = state.position == 'top';
        return (
            <div className="tabbar" style={{
                backgroundColor: state.backgroundColor,
                display: hidden ? 'none' : 'flex',
                borderColor: state.borderStyle,
                height: 48,
                paddingBottom: isIphonex ? '34px' : '0'
            }}>
                {list.map((item, idx) => {
                    return (
                        <div onClick={() => {
                            this.onItemTap(idx)
                        }}
                             className="tabbar-item" key={idx}>
                            {!top ? (
                                <span style={{position: "relative"}}>
                                  {
                                    item.iconPath ? <img className="tabbar-icon"
                                                         src={active == idx ? item.selectedIconPath : item.iconPath}
                                                         alt=""/> : null
                                  }
                                  {item.badge ?
                                      <span className="tabbar-icon-badge">{item.badge}</span> : null
                                  }
                </span>
                            ) : null}
                            <p className="tabbar-label"
                               style={{color: active == idx ? state.selectedColor : state.color}}>
                                {item.text}
                                {(top && active == idx) ?
                                    <i className="tabbar-label-indicator"
                                       style={{borderColor: item.selectedColor}}/> : null}
                            </p>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default ReactDOM.render(<Tabbar/>, document.getElementById("tabbar"))
