import { doThen, doCatch, isX5App, resolveLocalFile, findReact } from './../utils';
import $script from 'scriptjs';
/*
 * type String 否 默认为 wgs84 返回 gps 坐标，gcj02 返回可用于wx.openLocation的坐标 success
 * Function 是 接口调用成功的回调函数，返回内容详见返回参数说明。 fail Function 否 接口调用失败的回调函数 complete
 * Function 否 接口调用结束的回调函数（调用成功、失败都会执行） 成功参数 latitude 纬度，浮点数，范围为-90~90，负数表示南纬
 * longitude 经度，浮点数，范围为-180~180，负数表示西经 speed 速度，浮点数，单位m/s accuracy 位置的精确度
 */
const mockReslut = {
		longitude : 116.45764191999997,  // 经度
		latitude : 39.8622934399999, // 纬度
		speed : -1 , // 速度
	 	accuracy : 65
}
const mockReslutGcj = {
		longitude : 116.46379226945713,   // 经度
		latitude : 39.86362921809104, // 纬度
		speed : -1,
		accuracy : 65
}
function getLocation(params) {
  params.type = params.type || "wgs84";

  if (navigator.geolocation) {
	  if(params.type == "wgs84"){
	    navigator.geolocation.getCurrentPosition(function (position) {
	      let res = position.coords;
	      doThen(params, res);
	    }, function (error) {
	    	doCatch(params,error);
	    }, {
	        type: params.type
	    });
      }else{
		  $script.get('//3gimg.qq.com/lightmap/components/geolocation/geolocation.min.js', function () {
			var geolocation = new qq.maps.Geolocation("XZGBZ-LGGCF-J33JI-J3NQP-ITV4Z-IRFQV", "微信小程序");  
		    geolocation.getLocation(function (position) {
		    	position.latitude = position.lat;
		    	position.longitude = position.lng;
		      doThen(params, position);
		    }, function (error) {
		    	doCatch(params,error);
		    });
		  },function(error){
			  doCatch(params,error);
		  });
      }
  } else {
    doCatch(params, "navigator.geolocation is not defined!");
  }
}


/*
 * function _getPosition(type){ return new Promise ((resolve,reject)=>{
 * if(navigator.geolocation){
 * navigator.geolocation.getCurrentPosition(position=>{ resolve(position);
 * },error=>{ reject(error) },{ type }) }else { reject('navigator.geolocation is
 * not defined!'); } }) }
 */



/*
 * latitude Float 是 纬度，范围为-90~90，负数表示南纬 longitude Float 是 经度，范围为-180~180，负数表示西经
 * scale INT 否 缩放比例，范围1~28，默认为28 name String 否 位置名 address String 否 地址的详细说明
 * success Function 否 接口调用成功的回调函数 fail Function 否 接口调用失败的回调函数 complete Function
 * 否 接口调用结束的回调函数（调用成功、失败都会执行）
 */
function openLocation(params) {
  params.scale = params.scale || 28;
  if (isX5App) {
    navigator.baiduMap.base.open({
      "center": { lon: params.longitude, lat: params.latitude },
      "zoomLevel": params.scale
    }, function () {
      doThen(params);
    }, function (error) {
      doCatch(params, error);
    });
  } else {
    window.open(`http://api.map.baidu.com/marker?location=${params.longitude},${params.latitude}&title=${params.name}&content=${params.address}&zoom=${params.scale}&output=html`, "_system");
    doThen(params);
  }
}


/*
 * wx.chooseLocation(OBJECT) 打开地图选择位置
 * 
 * OBJECT参数说明：
 * 
 * 参数 类型 必填 说明 success Function 是 接口调用成功的回调函数，返回内容详见返回参数说明。 cancel Function 否
 * 用户取消时调用 fail Function 否 接口调用失败的回调函数 complete Function 否
 * 接口调用结束的回调函数（调用成功、失败都会执行） success返回参数说明：
 * 
 * 参数 说明 name 位置名称 address 详细地址 latitude 纬度，浮点数，范围为-90~90，负数表示南纬 longitude
 * 经度，浮点数，范围为-180~180，负数表示西经
 * 
 * <iframe id="mapPage" width="100%" height="100%" frameborder=0
 *     src="http://apis.map.qq.com/tools/locpicker?search=1&type=1&key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77&referer=myapp">
 * </iframe>
 */

let options = {};
let iframe = null
let box = null;

function chooseLocation(params) {
	  options = params;
	  // 盒子DIV ，用来存放iframe 和 自定义的 header
	  box = document.createElement('div');
	  box.setAttribute("class","map-box");
	  // 自定义header
	  let head = document.createElement('div');
	  head.setAttribute("class","map-head");
	  let back = document.createElement('div');
	  back.innerText = "返回";
	  back.setAttribute("class","map-back");
	  back.setAttribute("id","map-back");
	  head.appendChild(back);
	  let title = document.createElement('h3');
	  title.setAttribute("class","map-title");
	  title.innerText = "位置"
	  head.appendChild(title);
	  let option = document.createElement('div');
	  option.innerText = "发送"
	  option.setAttribute("class","map-option");
	  option.setAttribute("id","map-send");
	  head.appendChild(option);
	  box.appendChild(head);
	  // iframe
	  iframe = document.createElement('iframe');
	  iframe.src = `https://apis.map.qq.com/tools/locpicker?search=1&type=1&backurl=${location.hash.substr(1)}&mapdraggable=1&key=XZGBZ-LGGCF-J33JI-J3NQP-ITV4Z-IRFQV&referer=微信小程序`;
	  if(params.latitude && params.longitude){
	  	  iframe.src += '&coord=' + params.latitude + "," + params.longitude;
	  }
	  iframe.style.height = "100%";
	  iframe.style.width = "100%";
	  iframe.style.position = "absolute";
	  iframe.frameborder = 0;
	  box.appendChild(iframe);
	  document.body.appendChild(box);
	  if (box) {
	  	document.getElementById("map-back").addEventListener('click',function(event){
      		event.preventDefault();
      		if(box){
      			document.body.removeChild(box);
    	        box = null;
      		} 
	    })
	  }
}

window.addEventListener('message', event => {
  // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
  const data = event.data;
  if (data && data.module == 'locationPicker') {// 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
    if (box) {
      	document.getElementById("map-send").addEventListener('click',function(event){
      		event.preventDefault()
      		doThen(options, {
      	      name: data.poiname,
      	      address: data.poiaddress,
      	      latitude: data.latlng.lat,
      	      longitude: data.latlng.lng
      	    });
            document.body.removeChild(box);
            box = null;
        })
      }
  } else {
    doCatch(options, '当前环境不支持 chooseLocation');
  }
}, false);


/*
 * wx.createMapContext(mapId) 创建并返回 map 上下文 mapContext 对象
 * 
 * mapContext mapContext 通过 mapId 跟一个 <map/> 组件绑定，通过它可以操作对应的 <map/> 组件。
 * 
 * mapContext 对象的方法列表
 * 
 * 方法 参数 说明 getCenterLocation OBJECT 获取当前地图中心的经纬度，返回的是 gcj02 坐标系，可以用于
 * wx.openLocation moveToLocation 无 将地图中心移动到当前定位点，需要配合map组件的show-location使用
 * getCenterLocation 的 OBJECT 参数列表
 * 
 * 参数 类型 必填 说明 success Function 否 接口调用成功的回调函数 ，res = { longitude: "经度",
 * latitude: "纬度"} fail Function 否 接口调用失败的回调函数 complete Function 否
 * 接口调用结束的回调函数（调用成功、失败都会执行）
 */
function createMapContext(mapId) {
  const map = document.getElementById(mapId);
  return findReact(map);
}

export { getLocation, openLocation, chooseLocation, createMapContext };
